import { createSlice } from "@reduxjs/toolkit";
var initialState = {};
let userString = localStorage.getItem("user");
if (userString) {
  let user = JSON.parse(userString);
  if (user) initialState = user;
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action) => {
      if (action.payload)
        localStorage.setItem("user", JSON.stringify(action.payload));
      return action.payload;
    },
    clearUser: (state) => {
      localStorage.clear();
      return {};
    },
  },
});

export const { setUser, clearUser } = authSlice.actions;

export default authSlice.reducer;
