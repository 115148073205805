import BaseURL from "../../data/Api/baseUrl";

const getAll = () => {
  return BaseURL.get("category");
};

const get = (id) => {
  return BaseURL.get(`category/${id}`);
};

const create = (data) => {
  return BaseURL.postForm(`category/admin/`, data);
};

const update = (_id, data) => {
  return BaseURL.put(`category/update/${_id}`, data);
};

const remove = (_id) => {
  return BaseURL.delete(`category/${_id}`);
};

const CategoryService = {
  getAll,
  get,
  create,
  update,
  remove,
};

export default CategoryService;
