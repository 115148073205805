import { createSlice } from "@reduxjs/toolkit";
var initialState = { patientId: "", patientTracking: [] };

const patientSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setPatient: (state, action) => {
            if (action.payload)
                return action.payload;
        },
    },
});

export const { setPatient } = patientSlice.actions;

export default patientSlice.reducer;
