import React, { Fragment, useEffect, useState } from "react";
import {
    Breadcrumb,
    BreadcrumbItem,
    Dropdown,
    ProgressBar,
    Row,
    Col,
    Table,
    Card,
    Container,
    Button,
} from "react-bootstrap";
import "../../Dashboard/dashboard.scss";
// chartjs plugin
import { Bar, Line } from "react-chartjs-2";
// eslint-disable-next-line
import { Chart as ChartJS } from "chart.js/auto";
// ReactApexChart
import ReactApexChart from "react-apexcharts";
import * as dashboardmain from "../../../data/maindashboard";
// material ui
import CircularProgress from "@mui/material/CircularProgress";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
// import DashboardService from "../../Redux/services/DashboardService";

import { useNavigate } from "react-router-dom";

import moment from "moment/moment";
import AppointmentsService from "../../../Redux/services/AppointmentsService";
const DocDashboard = () => {
    const navigate = useNavigate();

    const user = useSelector((state) => state.auth);

    const [analytics, setAnalytics] = useState(undefined);
    const [recentBookings, setRecentBookings] = useState([]);
    const [earnings, setEarnings] = useState();
    const [dailyPatients, setDailyPatients] = useState();

    const [pending, setPending] = useState("pending");

    const handleClick = (val) => {
        setPending(val);
    };

    const DailyPatients = async () => {
        try {
            let response = await AppointmentsService.getDailyPatientCount().then((res) => {
                console.log('daily list of patients', res.data?.data);
                setDailyPatients(res.data?.data);
                console.log('daily count of patients', dailyPatients);
            });
        } catch (error) {
            console.log(error);
        }
    };

    // const Appoinmentstatus = async () => {
    //     try {
    //         let response = await DashboardService.Appoinments_user().then((res) => {
    //             // console.log(res.data.data);
    //             setAppoinment_User(res.data);
    //         });
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    // const ThirtyDay = async () => {
    //     try {
    //         let response = await DashboardService.ThirtyDay().then((res) => {
    //             setThirty_day(res.data);
    //         });
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    // const Booking_Dash = async () => {
    //     try {
    //         let response = await DashboardService.Latest().then((res) => {
    //             setLatest_Appoinment(res.data.bookings);
    //         });
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    const getStatuscolor = (status) => {
        switch (status) {
            case "Completed":
                return "status bg-success";
            case "Requested":
                return "status bg-warning";
            case "Rejected":
                return "status bg-danger";
            case "Approved":
                return "status bg-info";
            case "Canceled":
                return "status bg-danger";

            default:
                return "status bg-warning";
        }
    };

    useEffect(() => {
        DailyPatients();
        // DailyUser();
        // Appoinmentstatus();
        // Booking_Dash();
        // ThirtyDay();
    }, []);

    const linechart = {
        labels: dailyPatients?.map((item) => moment(item?.k).format("MMM D - YYYY")),
        datasets: [
            {
                label: "Daily Patients",
                data: dailyPatients?.map((item) => item.v),
                borderWidth: 3,
                backgroundColor: "transparent",
                borderColor: "#6259ca",
                pointBackgroundColor: "ffffff",
                pointRadius: 0,
                type: "line",
                tension: 0.4,
            },
        ],
    };

    const Radialbar = {
        className: "forth circle",
        // pending: [((analytics?.totalIncompleteAppointments / (analytics?.totalIncompleteAppointments + analytics?.totalCompletedAppointments)) * 10) || 0, ((analytics?.totalCompletedAppointments / (analytics?.totalIncompleteAppointments + analytics?.totalCompletedAppointments)) * 10) || 0], // Provide a default value if 'appoinment_user.data?.pending' is undefined
        pending: [analytics?.totalIncompleteAppointments, analytics?.totalCompletedAppointments], // Provide a default value if 'appoinment_user.data?.pending' is undefined
        fill: {
            type: "gradient",
            gradient: {
                shade: "dark",
                type: "vertical",
                // gradientToColors: ["#87D4F9"],
                // stops: [0, 100],
            },
        },
        options: {
            labels: ["Pending", "Completed"],
            colors: ["#9c27b0", "#6259ca"],
            chart: {
                type: "donut",
            },
            legend: {
                position: 'bottom'
            }
        },
    };

    // const Radialbars = {
    //     className: "forth circle",
    //     inProcess: [appoinment_user.data?.inProcess || 0],
    //     fill: {
    //         type: "gradient",
    //         gradient: {
    //             shade: "dark",
    //             type: "vertical",
    //             gradientToColors: ["#87D4F9"],
    //             stops: [0, 100],
    //         },
    //     },
    //     options: {
    //         labels: ["Inprogress"],
    //         colors: ["#6259ca"],
    //         chart: {
    //             type: "radialBar",
    //         },
    //         plotOptions: {
    //             radialBar: {
    //                 hollow: {},
    //             },
    //         },
    //     },
    // };

    const loadData = async () => {
        console.log('here')
        try {
            const response = await AppointmentsService.getAnalytics().then((res) => {
                console.log("my analytics", res.data?.analytics);
                setAnalytics(res.data?.analytics[0]);
                console.log('analytics data', analytics);
            });
        } catch (error) {
            console.error(error);
        }

        try {
            const response = await AppointmentsService.getRecentAppointments().then((res) => {
                console.log('recents',)
                setRecentBookings(res.data?.appointments);
                console.log('bookings data', recentBookings);
            });
        } catch (error) {
            console.error(error);
        }

        try {
            const response = await AppointmentsService.getMyWallet().then((res) => {
                console.log('wallet earnings', res.data?.data.unsettle_amount)
                setEarnings(res.data?.data.unsettle_amount);
                console.log('earnings data', earnings);
            });
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (analytics === undefined || recentBookings.length === 0 || earnings === undefined) {
            loadData();
        }
    }, [analytics, recentBookings, earnings]);

    // var permission = JSON.parse(localStorage.getItem("permission"));
    // console.log('permission', permission);

    // if (permission) {
    //     const checkUsername = (obj) => obj.name !== "all-permissions";
    //     let currentpermission = permission.some(checkUsername);
    //     if (!currentpermission) {
    //         return (
    //             <div className="pemission">
    //                 <p>dont have access to this page</p>
    //             </div>
    //         );
    //     } else {
    return (
        <Fragment>
            {/* <!-- Page Header --> */}
            <div className="page-header">
                <div>
                    <h2 className="main-content-title tx-24 mg-b-5">
                        Welcome To Dashboard
                    </h2>
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Breadcrumb>Home</Breadcrumb>
                        </BreadcrumbItem>
                        <BreadcrumbItem active>Dashboard</BreadcrumbItem>
                    </Breadcrumb>
                </div>
            </div>
            {/* <!-- End Page Header --> */}

            {/* <!--Row--> */}
            <Row className="row-sm">
                <Col sm={12} lg={12} xl={12}>
                    <Row className=" row-sm  mt-lg-4">
                        <Col sm={12} lg={12} xl={12}>
                            <Card className="bg-primary custom-card card-box">
                                <Card.Body className="p-4">
                                    <Row className="align-items-center">
                                        <Col className="col-xl-8 col-sm-6 col-12 ">
                                            <h4 className="d-flex  mb-3">
                                                <span className="font-weight-bold text-white ">
                                                    Hi, {user.name}
                                                </span>
                                            </h4>
                                            {/* <p className="tx-white-7 mb-1">
                        You have two projects to finish, you had completed
                        <b className="text-warning"> 57%</b> from your montly
                        level, Keep going to your level
                      </p> */}
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    {/* <!--Row --> */}

                    {/* <!--Row--> */}
                    <Row className="row-sm">
                        <Col sm={12} md={6} lg={6} xl={4}>
                            <Card className="custom-card">
                                <Card.Body>
                                    <div className="card-item">
                                        <div className="card-item-icon card-icon">
                                            <svg
                                                className="text-primary"
                                                xmlns="http://www.w3.org/2000/svg"
                                                enableBackground="new 0 0 24 24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                width="24"
                                            >
                                                <g>
                                                    <rect
                                                        height="14"
                                                        opacity=".3"
                                                        width="14"
                                                        x="5"
                                                        y="5"
                                                    />
                                                    <g>
                                                        <rect fill="none" height="24" width="24" />
                                                        <g>
                                                            <path d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M19,19H5V5h14V19z" />
                                                            <rect height="5" width="2" x="7" y="12" />
                                                            <rect height="10" width="2" x="15" y="7" />
                                                            <rect height="3" width="2" x="11" y="14" />
                                                            <rect height="2" width="2" x="11" y="10" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                        <div className="card-item-title mb-2">
                                            <label className="main-content-label tx-13 font-weight-bold mb-1">
                                                Total Bookings
                                            </label>
                                        </div>
                                        <div className="card-item-body">
                                            <div className="card-item-stat">
                                                <h4 className="font-weight-bold">
                                                    {analytics?.totalAppointments}
                                                </h4>
                                                {/* <small>
                          <b className="text-success">55%</b> higher
                        </small> */}
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={12} md={6} lg={6} xl={4}>
                            <Card className="custom-card">
                                <Card.Body>
                                    <div className="card-item">
                                        <div className="card-item-icon card-icon">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                width="24"
                                            >
                                                <path d="M0 0h24v24H0V0z" fill="none" />
                                                <path
                                                    d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33C19.38 15.49 20 13.82 20 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z"
                                                    opacity=".3"
                                                />
                                                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33C4.62 15.49 4 13.82 4 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z" />
                                            </svg>
                                        </div>
                                        <div className="card-item-title mb-2">
                                            <label className="main-content-label tx-13 font-weight-bold mb-1">
                                                Total Patients
                                            </label>
                                            {/* <span className="d-block tx-12 mb-0 text-muted">
                        Employees joined this month
                      </span> */}
                                        </div>
                                        <div className="card-item-body">
                                            <div className="card-item-stat">
                                                <h4 className="font-weight-bold">
                                                    {analytics?.totalPatients}
                                                </h4>
                                                {/* <small>
                          <b className="text-success">5%</b> Increased
                        </small> */}
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={12} md={12} lg={12} xl={4}>
                            <Card className="card custom-card">
                                <Card.Body>
                                    <div className="card-item">
                                        <div className="card-item-icon card-icon">
                                            <svg
                                                className="text-primary"
                                                xmlns="http://www.w3.org/2000/svg"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                width="24"
                                            >
                                                <path d="M0 0h24v24H0V0z" fill="none" />
                                                <path
                                                    d="M12 4c-4.41 0-8 3.59-8 8s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8zm1.23 13.33V19H10.9v-1.69c-1.5-.31-2.77-1.28-2.86-2.97h1.71c.09.92.72 1.64 2.32 1.64 1.71 0 2.1-.86 2.1-1.39 0-.73-.39-1.41-2.34-1.87-2.17-.53-3.66-1.42-3.66-3.21 0-1.51 1.22-2.48 2.72-2.81V5h2.34v1.71c1.63.39 2.44 1.63 2.49 2.97h-1.71c-.04-.97-.56-1.64-1.94-1.64-1.31 0-2.1.59-2.1 1.43 0 .73.57 1.22 2.34 1.67 1.77.46 3.66 1.22 3.66 3.42-.01 1.6-1.21 2.48-2.74 2.77z"
                                                    opacity=".3"
                                                />
                                                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm.31-8.86c-1.77-.45-2.34-.94-2.34-1.67 0-.84.79-1.43 2.1-1.43 1.38 0 1.9.66 1.94 1.64h1.71c-.05-1.34-.87-2.57-2.49-2.97V5H10.9v1.69c-1.51.32-2.72 1.3-2.72 2.81 0 1.79 1.49 2.69 3.66 3.21 1.95.46 2.34 1.15 2.34 1.87 0 .53-.39 1.39-2.1 1.39-1.6 0-2.23-.72-2.32-1.64H8.04c.1 1.7 1.36 2.66 2.86 2.97V19h2.34v-1.67c1.52-.29 2.72-1.16 2.73-2.77-.01-2.2-1.9-2.96-3.66-3.42z" />
                                            </svg>
                                        </div>
                                        <div className="card-item-title  mb-2">
                                            <label className="main-content-label tx-13 font-weight-bold mb-1">
                                                Total Earnings
                                            </label>
                                            {/* <span className="d-block tx-12 mb-0 text-muted">
                        Previous month vs this months
                      </span> */}
                                        </div>
                                        <div className="card-item-body">
                                            <div className="card-item-stat">
                                                <h4 className="font-weight-bold">
                                                    {earnings}
                                                </h4>
                                                {/* <small>
                          <b className="text-danger">12%</b> decrease
                        </small> */}
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    {/* <!--End row--> */}
                    {/* <!--row--> */}
                    <Row className="row-sm">
                        {/* <!-- col end --> */}
                        <Col sm={12} md={12} lg={6} xl={6}>
                            <Card className="custom-card overflow-hidden kingdom_cards">
                                <Card.Header className="border-bottom-0 pb-0">
                                    <div>
                                        <div className="d-md-flex mb-2">
                                            <label className="main-content-label my-auto pt-2">
                                                Appointment Status
                                            </label>
                                            {/* <div className="ms-auto mt-3 d-flex">
                                                <div
                                                    className="me-3 d-flex text-muted tx-13 bars_kingdom"
                                                // onClick={() => handleClick("pending")}
                                                >
                                                    Pending
                                                </div>
                                                <div
                                                    className="d-flex text-muted tx-13 bars_kingdom"
                                                >
                                                    In Progress
                                                </div>
                                            </div> */}
                                        </div>
                                        {/* <span className="d-block tx-12 mt-2 mb-0 text-muted">
                      UX UI & Backend Developement.
                    </span> */}
                                    </div>
                                </Card.Header>
                                <Card.Body className="py-0">
                                    <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        {/* <Col sm={12} md={12} lg={6} xl={6}> */}
                                        <div className="forth circle">
                                            {/* <div> */}
                                            {/* {pending === "pending" ? ( */}
                                            {analytics && <ReactApexChart
                                                type="donut"
                                                options={Radialbar.options}
                                                series={Radialbar.pending}
                                                height={320}
                                            />}
                                            {/* ) : null} */}
                                            {/* {pending === "inProcess" ? ( */}
                                            {/* <ReactApexChart
                                                                    type="radialBar"
                                                                    options={Radialbars.options}
                                                                    series={Radialbars.inProcess}
                                                                    height={320}
                                                                />
                                                            ) : null} */}
                                            {/* </div> */}
                                        </div>
                                        {/* </Col> */}
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        {/* <!-- col end --> */}
                        <Col sm={12} md={12} lg={6} xl={6}>
                            <Card className="custom-card overflow-hidden  kingdom_cards">
                                <Card.Header className="border-bottom-0">
                                    <div>
                                        <label className="main-content-label mb-2">
                                            Daily Patient Count
                                        </label>
                                        <span className="d-block tx-12 mb-0 text-muted">
                                            {/* The Project Budget is a tool used by project managers to
                      estimate the total cost of a project */}
                                        </span>
                                    </div>
                                </Card.Header>
                                <Card.Body className="ps-12">
                                    <div>
                                        <Container>
                                            <div className="chart-dropshadow2 ht-300">
                                                <Line
                                                    options={dashboardmain.linechartoptions}
                                                    data={linechart}
                                                    className="barchart chart-dropshadow2 ht-300 chartjs-render-monitor"
                                                    height="100"
                                                />
                                            </div>
                                        </Container>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={12}>
                            <Card className="custom-card mg-b-20">
                                <Card.Body>
                                    <Card.Header className="card-header border-bottom-0 pt-0 ps-0 pe-0 d-flex">
                                        <div>
                                            <label className="main-content-label mb-2">
                                                Recent Bookings
                                            </label>
                                            {/* <span className="d-block tx-12 mb-3 text-muted">
                        A task is accomplished by a set deadline, and must
                        contribute toward work-related objectives.
                      </span> */}
                                        </div>
                                        <Dropdown className="ms-auto">
                                            <Dropdown.Toggle
                                                variant="default"
                                                className="option-dots"
                                            >
                                                <i className="fe fe-more-vertical"></i>
                                            </Dropdown.Toggle>
                                            <DropdownMenu style={{ margin: "0px" }}>
                                                <DropdownItem href="#">Task</DropdownItem>
                                                <DropdownItem href="#">Team</DropdownItem>
                                                <DropdownItem href="#">Status</DropdownItem>
                                                <Dropdown.Divider />
                                                <DropdownItem href="#">
                                                    <i className="fa fa-cog me-2"></i> Settings
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </Card.Header>
                                    <div className=" tasks">
                                        <Table
                                            responsive
                                            hover
                                            className="card-table table-vcenter text-nowrap mb-0 border hover"
                                        >
                                            <thead>
                                                <tr>
                                                    {/* <th className="wd-lg-10p">_id</th> */}
                                                    <th className="wd-lg-20p">Doctor</th>
                                                    <th className="wd-lg-20p">Patient</th>
                                                    <th className="wd-lg-20p">Booked_by</th>
                                                    <th className="wd-lg-20p">
                                                        Appointment Start Time
                                                    </th>
                                                    <th className="wd-lg-20p">
                                                        Appointment End Time
                                                    </th>
                                                    <th className="wd-lg-20p">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {recentBookings && recentBookings.map((items, index) => (
                                                    <tr
                                                        key={index}
                                                        data-index={index}
                                                        onClick={() =>
                                                            navigate(`/viewdoctor/${items?.doctor?._id}`)
                                                        }
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        <td className="text-nowrap">
                                                            <div className="demo-avatar-group">
                                                                <span className="mt-1">
                                                                    {items?.doctor?.display_name}
                                                                </span>
                                                            </div>
                                                        </td>
                                                        <td className="map_kingdom">
                                                            <span className="mt-1">
                                                                {items.patient?.name}
                                                            </span>
                                                        </td>
                                                        <td className="map_kingdom">
                                                            <span className="mt-1">
                                                                {items?.booked_by?.name}
                                                            </span>
                                                        </td>
                                                        <td className="map_kingdom ">
                                                            <span className="mt-1">
                                                                {moment(
                                                                    items?.appointment_start_time
                                                                ).format("MMM D - YY / hh:mm a")}
                                                            </span>
                                                        </td>
                                                        <td className="map_kingdom">
                                                            <span className="mt-1">
                                                                {moment(items?.appointment_end_time).format(
                                                                    "hh:mm a"
                                                                )}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span
                                                                className={`${getStatuscolor(
                                                                    items.status
                                                                )}`}
                                                            ></span>
                                                            {items.status}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>

                        {/* <!-- col end --> */}
                    </Row>
                    {/* <!-- Row end --> */}
                </Col>
                {/* <!-- col end --> */}
            </Row>
            {/* <!-- Row end --> */}
        </Fragment>
    );
    // }
    // } else {
    //     return (
    //         <div className="pemission">
    //             <p>dont have access to this page</p>
    //         </div>
    //     );
    // }
}

DocDashboard.propTypes = {};

DocDashboard.defaultProps = {};

export default DocDashboard;
