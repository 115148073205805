import BaseURL from "../../data/Api/baseUrl";

const getAll = (status) => {
  console.log("status", status);
  return BaseURL.get(`appointment/getAppointmentsOfDoctor?status=${status}`);
};

const getAnalytics = () => {
  return BaseURL.get(`doctor/doctor_analytics`);
};

const getRecentAppointments = () => {
  const page = 1;
  const limit = 10;
  return BaseURL.get(`appointment/my_appointments?page=${page}&limit=${limit}`);
};

const getMyWallet = () => {
  return BaseURL.get(`doctor/wallet`);
};

const getMyPatients = () => {
  return BaseURL.get(`appointment/get_my_patients`);
};

const getDailyPatientCount = () => {
  return BaseURL.get('doctor/my_patient_monthly_counts');
}

const get = (id) => {
  return BaseURL.get(`appointment/doctor/${id}`);
};

const create = (data) => {
  return BaseURL.postForm(`/user/admin/`, data);
};

const update = (_id, data) => {
  return BaseURL.putForm(`user/admin/updateProfile/${_id}`, data);
};

const remove = (_id) => {
  return BaseURL.delete(`user/${_id}`);
};
const updateNameAndNationalId = (userId, data) => {
  return BaseURL.putForm(`user/admin/updateProfile/${userId}`, data);
};

const AppointmentsService = {
  getAll,
  getMyPatients,
  getAnalytics,
  getRecentAppointments,
  getMyWallet,
  getDailyPatientCount,
  get,
  create,
  update,
  remove,
  updateNameAndNationalId,
};

export default AppointmentsService;
