import { createSlice, createAsyncThunk, } from "@reduxjs/toolkit";
import MyBookingService from "../services/MyBooking";


const initialState = { loading: true, page: 1, data: [], };

export const createbooking = createAsyncThunk(
    "mybooking/create",
    async ({ name_en, file }) => {
        console.log("file", file);
        const res = await MyBookingService.create({ name_en, file });
        return res.data;
    }
);

export const retrievebooking = createAsyncThunk(
    "mybooking/retrieve",
    async ({ status, page, pageLimit, user_type, doctor_id }) => {
        const res = await MyBookingService.getAll({ status, page, pageLimit, user_type, doctor_id });
        return res.data;
    }
);

export const updatebooking = createAsyncThunk(
    "mybooking/update",
    async ({ _id, data }) => {
        const res = await MyBookingService.update(_id, data);
        return res.data.user;
    }
);

export const deletebooking = createAsyncThunk("user/delete", async ({ _id }) => {
    await MyBookingService.remove(_id);
    return { _id };
});

const mybookingSlice = createSlice({
    name: "mybooking",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createbooking.fulfilled, (state, action) => {
                state.push(action.payload);
            })
            .addCase(retrievebooking.fulfilled, (state, action) => {
                return action.payload;
            })
            .addCase(updatebooking.fulfilled, (state, action) => {
                const index = state.data.findIndex(
                    (user) => user._id === action.payload._id
                );
                if (index !== -1) {
                    state[index] = action.payload;
                }
            })
            .addCase(deletebooking.fulfilled, (state, action) => {
                return state.filter((user) => user._id !== action.payload._id);
            });
    },
});

export default mybookingSlice.reducer;

