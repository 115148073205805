import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import PeopleService from "../services/PeopleService";

const initialState = [];

export const retrievepeople = createAsyncThunk(
  "people/retrieve",
  async (_id) => {
    const res = await PeopleService.getAll(_id);
    return res.data.people;
  }
);

export const createpeople = createAsyncThunk(
  "people/create",
  async ({
    name,
    user_id,
    relationship,
    height,
    weight,
    date_of_birth,
    blood_group,
    gender,
    file: image,
  }) => {
    console.log("data", name);
    const res = await PeopleService.create({
      name,
      user_id,
      relationship,
      height,
      weight,
      date_of_birth,
      blood_group,
      gender,
      file: image,
    });
    return res.data;
  }
);

// export const retrievedeviceid = createAsyncThunk(
//   "device/retrieveid",
//   async ({ _id }) => {
//     await DeviceService.get(_id);
//     return { _id };

//   }
// );

export const updatepeople = createAsyncThunk(
  "people/update",
  async ({ _id, data }) => {
    const res = await PeopleService.update(_id, data);
    return res.data.user;
  }
);

// export const deletedevice = createAsyncThunk(
//   "device/delete",
//   async ({ _id }) => {
//     await DeviceService.remove(_id);
//     console.log("id",_id);
//     return { _id };

//   }
// );

const peopleSlice = createSlice({
  name: "people",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrievepeople.fulfilled, (state, action) => {
        return action.payload;
      })
      .addCase(createpeople.fulfilled, (state, action) => {
        state.push(action.payload);
      })
      .addCase(updatepeople.fulfilled, (state, action) => {
        const index = state.findIndex(
          (people) => people._id === action.payload._id
        );
        if (index !== -1) {
          state[index] = action.payload;
        }
      });
    //   .addCase(deletedevice.fulfilled, (state, action) => {
    //     return state.filter((device) => device._id !== action.payload._id);
    //   });
  },
});

export default peopleSlice.reducer;
