import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import CategoryService from "../services/CategoryService";

const initialState = [];

export const createCategory = createAsyncThunk(
  "category/create",
  async ({ name_en, file }) => {
    console.log("file", file);
    const res = await CategoryService.create({ name_en, file });
    return res.data;
  }
);

export const retrieveCategory = createAsyncThunk(
  "category/retrieve",
  async () => {
    const res = await CategoryService.getAll();
    return res.data.category;
  }
);

export const retrieveCategoryid = createAsyncThunk(
  "category/retrieveid",
  async ({ _id }) => {
    await CategoryService.get(_id);
    return { _id };
  }
);

export const updateCategory = createAsyncThunk(
  "category/update",
  async ({ _id, data }) => {
    const res = await CategoryService.update(_id, data);
    return res.data.category;
  }
);

export const deleteCategory = createAsyncThunk(
  "category/delete",
  async ({ _id }) => {
    await CategoryService.remove(_id);
    console.log("id", _id);
    return { _id };
  }
);

const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createCategory.fulfilled, (state, action) => {
        state.push(action.payload);
      })
      .addCase(retrieveCategory.fulfilled, (state, action) => {
        return action.payload;
      })
      .addCase(updateCategory.fulfilled, (state, action) => {
        const index = state.findIndex(
          (category) => category._id === action.payload._id
        );
        if (index !== -1) {
          state[index] = action.payload;
        }
      })
      .addCase(deleteCategory.fulfilled, (state, action) => {
        return state.filter((category) => category._id !== action.payload._id);
      });
  },
});

export default categorySlice.reducer;
