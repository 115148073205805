import BaseURL from "../../data/Api/baseUrl";
import BaseURLMultiPart from "../../data/Api/baseUrlMultiPart";

const getAll = ({ status, page, pageLimit, user_type }) => {
  return BaseURL.get(
    `user/getAllUsers?status=${status}&page=${page}&user_type=${user_type}&limit=${pageLimit}`
  );
};

const get = (id) => {
  return BaseURL.get(`user/admin/userInfo/${id}`);
};

const getNotifications = () => {
  return BaseURL.get("notification/user/notifications");
}

const getLatestNotifs = () => {
  return BaseURL.get("notification/user/latestNotifications");
}

const create = ({ name, password, email, user_type, role }) => {
  return BaseURL.post(`user/admin/user/createAdminUser`, {
    name,
    password,
    email,
    user_type,
    role,
  });
};

const addDocument = (formData) => {
  return BaseURL.post(`appointment/documents/admin`, formData);
};

const addDocMultipart = (formData) => {
  return BaseURLMultiPart.post(`appointment/documents/admin`, formData);
}

const update = (_id, data) => {
  return BaseURL.putForm(`user/admin/updateProfile/${_id}`, data);
};

const remove = (_id) => {
  return BaseURL.delete(`user/${_id}`);
};
const updateNameAndNationalId = (userId, data) => {
  return BaseURL.putForm(`user/admin/updateProfile/${userId}`, data);
};

const UpdateWorkingHours = ({ _id, workingHoursData }) => {
  return BaseURL.put(`/doctor/updateWorkingTime/${_id}`, workingHoursData);
};

const UserService = {
  getAll,
  get,
  getNotifications,
  getLatestNotifs,
  addDocument,
  addDocMultipart,
  create,
  update,
  remove,
  updateNameAndNationalId,
  UpdateWorkingHours,
};

export default UserService;
