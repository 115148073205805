import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DoctorService from "../services/DoctorService";

const initialState = { loading: true, page: 1, data: [] };

export const retrieveDoctor = createAsyncThunk(
  "doctor/retrieve",
  async ({ page, pageLimit }) => {
    const res = await DoctorService.getAll({ page, pageLimit });
    return res.data;
  }
);

export const createDoctor = createAsyncThunk(
  "doctor/create",
  async ({
    display_name,
    category,
    email,
    profession,
    file: image,
    working_time,
    about,
    fee,
    gender,
    career_started,
    password,
  }) => {
    try {
      const res = await DoctorService.create({
        display_name,
        category,
        email,
        profession,
        file: image,
        working_time,
        about,
        fee,
        gender,
        career_started,
        password,
      });
      return res.data.doctor;
    } catch (error) {
      throw error.response.data;
    }
  }
);

export const retrieveDoctorid = createAsyncThunk(
  "doctor/retrieveid",
  async ({ _id }) => {
    const res = await DoctorService.get(_id);
    return res.data;
  }
);

export const updateDoctor = createAsyncThunk(
  "doctor/update",
  async ({ _id, data }) => {
    const res = await DoctorService.update({ _id, data });
    return res.data.doctor;
  }
);

export const deleteDoctor = createAsyncThunk(
  "doctor/delete",
  async ({ _id }) => {
    await DoctorService.remove(_id);
    return { _id };
  }
);

const doctorSlice = createSlice({
  name: "doctor",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createDoctor.fulfilled, (state, action) => {
        if (Array.isArray(state)) {
          state.push(action.payload);
        } else {
          console.error("state is not an array");
        }
      })
      .addCase(retrieveDoctor.fulfilled, (state, action) => {
        return action.payload;
      })
      .addCase(updateDoctor.fulfilled, (state, action) => {
        if (Array.isArray(state)) {
          const index = state.findIndex(
            (doctor) => doctor._id === action.payload._id
          );
          if (index !== -1) {
            state[index] = action.payload;
          }
        } else {
          // Handle the case when state is not an array
          // This could be an error or an unexpected state
          console.error("state is not an array");
        }
      })
      .addCase(deleteDoctor.fulfilled, (state, action) => {
        if (Array.isArray(state)) {
          return state.filter((doctor) => doctor._id !== action.payload._id);
        } else {
          console.error("state is not an array");
        }
      });
  },
});

export default doctorSlice.reducer;
