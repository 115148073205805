import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import UserService from "../services/UserService";
import BaseURL from "../../data/Api/baseUrl";

const initialState = { loading: true, page: 1, data: [] };

export const getNotification = createAsyncThunk(
    "notifications/retrieve",
    async () => {
        try {
            const res = await UserService.getNotifications();
            return res.data.notifications;
        } catch (error) {
            console.log('error is ', error);
        }
    });

export const getLatestNotification = createAsyncThunk(
    "notifications/latest",
    async () => {
        try {
            const res = await UserService.getLatestNotifs();
            return res.data.notifications;
        } catch (error) {
            console.log('error is ', error);
        }
    });


const notificationsSlice = createSlice({
    name: "notifications",
    initialState: {
        latestNotifications: [],
        notifications: [],
    },
    reducers: {
        addNotification: (state, action) => {
            state.push(action.payload);
        },
        removeNotification: (state, action) => {
            const notifId = action.payload;
            return state.filter((notif) => notif.id !== notifId);
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getNotification.fulfilled, (state, action) => {
            state.notifications = action.payload;
        })
            .addCase(getLatestNotification.fulfilled, (state, action) => {
                state.latestNotifications = action.payload;
            })
    }
})

export const { addNotification, removeNotification } = notificationsSlice.actions;
export default notificationsSlice.reducer;