import BaseURL from "../../data/Api/baseUrl";

const getAll = ({ status, page, pageLimit, doctor_id }) => {
  //   console.log("doctor_ids", doctor_id);
  return BaseURL.get(
    `appointment/admin/appointmentsPaginatedList?status=${status}&limit=${pageLimit}&doctor=${doctor_id}`
  );
};

const get = (id) => {
  return BaseURL.get(`user/admin/userInfo/${id}`);
};

const create = (data) => {
  return BaseURL.postForm(`/user/admin/`, data);
};

const update = (_id, data) => {
  return BaseURL.putForm(`user/admin/updateProfile/${_id}`, data);
};

const remove = (_id) => {
  return BaseURL.delete(`user/${_id}`);
};
const updateNameAndNationalId = (userId, data) => {
  return BaseURL.putForm(`user/admin/updateProfile/${userId}`, data);
};

const MyBookingService = {
  getAll,
  get,
  create,
  update,
  remove,
  updateNameAndNationalId,
};

export default MyBookingService;
