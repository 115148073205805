import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import UserService from "../services/UserService";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

const initialState = { loading: true, page: 1, data: [] };

export const createuser = createAsyncThunk(
  "user/create",
  async ({ name, password, email, user_type, role }) => {
    try {
      const res = await UserService.create({
        name,
        password,
        email,
        user_type,
        role,
      });
      return res.data;
    } catch (error) {
      console.log("error_user", error.response.data.error.message);
      throw error.response.data.error.message;
    }
  }
);

export const retrieveuser = createAsyncThunk(
  "user/retrieve",
  async ({ status, page, pageLimit, user_type }) => {
    const res = await UserService.getAll({
      status,
      page,
      pageLimit,
      user_type,
    });
    return res.data;
  }
);

export const updateuser = createAsyncThunk(
  "user/update",
  async ({ _id, data }) => {
    const res = await UserService.update(_id, data);
    return res.data.user;
  }
);

export const deleteuser = createAsyncThunk("user/delete", async ({ _id }) => {
  await UserService.remove(_id);
  return { _id };
});

export const addUserDocument = createAsyncThunk(
  "user/addUserDocument",
  async (data
    // { type, name, user_id, appointment_id, file: image}
  ) => {
    console.log('the data', data)
    const notify = () => toast("Document Uploaded Successfully!");

    try {
      const res = await UserService.addDocMultipart(data
        //   { type, name, user_id, appointment_id, file: image }
      );
      if (res.data.status === "success") {
        notify();
        return res.data;
      }
    } catch (error) {
      console.log(error?.response?.data?.error);
      Swal.fire({ title: 'Error', text: error?.response?.data?.error, icon: "warning", })
    }
  });

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createuser.fulfilled, (state, action) => {
        // state.push(action.payload);
      })
      .addCase(retrieveuser.fulfilled, (state, action) => {
        return action.payload;
      })
      .addCase(updateuser.fulfilled, (state, action) => {
        const index = state.data.findIndex(
          (user) => user._id === action.payload._id
        );
        if (index !== -1) {
          state[index] = action.payload;
        }
      })
      .addCase(deleteuser.fulfilled, (state, action) => {
        return state.filter((user) => user._id !== action.payload._id);
      })
      .addCase(addUserDocument.fulfilled, (state, action) => {
        return action.payload;
      });
  },
});

export default userSlice.reducer;
