import BaseURL from "../../data/Api/baseUrl";

const getAll = (_id) => {
  return BaseURL.get(`user/admin/getPeopleByUser/${_id}`);
};

const get = (id) => {
  return BaseURL.get(`people/${id}`);
};

const create = ({
  name,
  user_id,
  relationship,
  height,
  weight,
  date_of_birth,
  blood_group,
  gender,
  file: image,
}) => {
  return BaseURL.postForm(`user/admin/addPeople`, {
    name,
    user_id,
    relationship,
    height,
    weight,
    date_of_birth,
    blood_group,
    gender,
    file: image,
  });
};

const update = (_id, data) => {
  return BaseURL.putForm(`user/admin/updateProfile/${_id}`, data);
};

const remove = (_id) => {
  return BaseURL.delete(`people/${_id}`);
};

const PeopleService = {
  getAll,
  get,
  create,
  update,
  remove,
};

export default PeopleService;
