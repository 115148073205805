import BaseURL from "../../data/Api/baseUrl";

const getAll = ({ page, pageLimit }) => {
  console.log("page", page);
  return BaseURL.get(`doctor/list?page=${page}&limit=${pageLimit}`);
};

const get = (_id) => {
  return BaseURL.get(`doctor/admin/${_id}`);
};

const create = (
  display_name,
  category,
  email,
  profession,
  file,
  working_time,
  about,
  fee,
  gender,
  career_started,
  password
) => {
  return BaseURL.postForm(
    `/doctor/admin/`,
    display_name,
    category,
    email,
    profession,
    file,
    working_time,
    about,
    fee,
    gender,
    career_started,
    password
  );
};

const update = ({ _id, data }) => {
  return BaseURL.putForm(`doctor/update/${_id}`, data);
};

const remove = (_id) => {
  return BaseURL.delete(`doctor/${_id}`);
};

const DoctorService = {
  getAll,
  get,
  create,
  update,
  remove,
};

export default DoctorService;
