import React, { Fragment, useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.scss";
import Loader from "./layouts/Loader/Loader";
import Auth from "./Authentication/auth";
import { Provider } from "react-redux";
import store from "./Redux/Store";
import Router from "./Router";

const Root = () => {
  // const permission = async () => {
  //   let response = await PermissionService.permission()
  //   const myArrayString = JSON.stringify(response.data.permission);
  //   localStorage.setItem("permission", myArrayString);
  //   // console.log('permissi', myArrayString)
  // }
  // useEffect(() => {
  //   permission();
  // }, [])

  return (
    <Provider store={store}>
      <Fragment>
        <BrowserRouter>
          <React.Suspense fallback={<Loader />}>
            <Router />
          </React.Suspense>
        </BrowserRouter>
      </Fragment>
    </Provider>
  );
};
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);
