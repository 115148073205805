import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./Slices/AuthSlice";
import userSlice from "./Slices/UserSlice";
import peopleSlice from "./Slices/PeopleSlice";
import doctorSlice from "./Slices/DoctorSlice";
import mybookingSlice from "./Slices/MyBookingSlice";
import categorySlice from "./Slices/CategorySlice";
import notificationSlice from "./Slices/NotificationSlice";
import patientSlice from "./Slices/PatientSlice";

const store = configureStore({
  reducer: {
    auth: authSlice,
    user: userSlice,
    doctor: doctorSlice,
    people: peopleSlice,
    mybooking: mybookingSlice,
    category: categorySlice,
    notification: notificationSlice,
    patient: patientSlice,
  },
});

export default store;
