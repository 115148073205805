import Axios from "axios";

const BaseURLMultiPart = Axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});

const token = localStorage.getItem("token");
if (token) {
    BaseURLMultiPart.defaults.headers.common["Authorization"] = `${token}`;
}

BaseURLMultiPart.defaults.headers.post["Content-Type"] = "multipart/form-data";

export default BaseURLMultiPart;
